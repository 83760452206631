import { cn } from '#app/utils/misc'

export type SpinnerProps = {
	size?: number
	color?: string
	className?: string
}

export function Spinner({
	size = 5,
	color = 'text-gray-400 dark:text-gray-400',
	className,
}: SpinnerProps) {
	return (
		<svg
			className={cn(
				color,
				`flex h-${size} w-${size} animate-spin`,
				className ?? '',
			)}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
		>
			<circle
				className="opacity-25"
				cx="12"
				cy="12"
				r="10"
				stroke="currentColor"
				strokeWidth="4"
			></circle>
			<path
				className="opacity-75"
				fill="currentColor"
				d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
			></path>
		</svg>
	)
}
